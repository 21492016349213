<template>
  <div class="pager">
    <div class="pager-items">
      <a href="javascript:void(0)" @click="update(1)" class="pager-first"></a>
      <a href="javascript:void(0)" @click="update(page - 1)" class="pager-prev"></a>
      <a v-if="max >= page && page > 6" href="javascript:void(0)" @click="update(1)" class="pager-item">1</a>
      <a v-if="max >= page && page > 6" href="javascript:void(0)" class="pager-item dots">...</a>
      <template v-for="(pageItem, index) in pages">
        <a :key="index"
           href="javascript:void(0)"
           class="pager-item"
           @click="update(pageItem)"
           :class="{'pager-current': page == pageItem}">
          {{ pageNumber(pageItem) }}
        </a>
      </template>
      <a v-if="max > page && page > 6" href="javascript:void(0)" class="pager-item dots">...</a>
      <a v-if="max > page && page > 6" href="javascript:void(0)" @click="update(max)" class="pager-item">{{ max }}</a>
      <a href="javascript:void(0)" @click="update(page + 1)" class="pager-next"></a>
      <a href="javascript:void(0)" @click="update(max)" class="pager-last"></a>
    </div>
  </div>
</template>

<script>
import {eventBus} from '../main'
export default {
  name: "Paginator",
  props: ['limit', 'offset', 'count'],
  data() {
    return {
      page: Math.ceil(this.offset/this.count) + 1
    }
  },
  methods: {
    update(page) {
      page = Math.ceil(page)
      if(page <= this.max && page > 0) {
        this.page = page
      }
    },
    pageNumber(page) {
      return Math.ceil(page) < 10 ? '0' + page : page
    }
  },
  computed: {
    max() {
      return Math.ceil(this.count/this.limit)
    },
    pages() {
      let temp = [];
      for(let i = 1; i <= this.max; i++) {
        temp.push(i);
      }
      return temp.splice(this.page - 1, 6)
    }
  },
  watch: {
    page() {
      eventBus.$emit('paging', {
        offset: this.page * this.limit - this.limit
      })
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }, 500)
    }
  }
}
</script>

<style scoped>
.pager-item.dots::before {
  display: none;
}
</style>