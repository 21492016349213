<template>
  <div class="accordion" id="acceleration-learn">
    <div class="accordion__item container-shadow">
      <div class="accordion__header" id="headingOne">
        <h2 class="collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
            aria-controls="collapseOne">
          Информация о старте набора региональных тренеров
        </h2>
      </div>
      <div id="collapseOne" class="collapse accordion__body show" aria-labelledby="headingOne" data-parent="#acceleration-learn">
        <div class="section__acceleration-description-text mb-2">
          <p>Школа экспорта Российского экспортного центра <b class="blue-text">объявляет о старте отбора кандидатов</b> в региональные тренеры для дальнейшего обучения по программе <b class="blue-text">«Организация экспортной деятельности российских предприятий для тренеров».</b></p>
          <p>К участию в отборе приглашаются <b class="blue-text">специалисты с практическим опытом экспортной деятельности</b>: действующие сотрудники региональных центров поддержки экспорта, представители бизнес-сообщества, федеральных и региональных институтов развития, инфраструктуры поддержки МСП и экспорта, некоммерческих организаций и объединений, а также независимые соискатели с опытом работы в сфере ВЭД.</p>
          <p>По результатам обучения кандидатам будет присвоена квалификация <b class="blue-text">регионального тренера Школы экспорта РЭЦ</b> по соответствующим экспортным семинарам.</p>
        </div>
        <div class="section__acceleration-description-text mb-2">
          <h3 class="mb-2">Для участия в конкурсном отборе кандидатам необходимо:</h3>
          <ul class="list list_dotted">
            <li>В срок <b>до 15 августа 2022</b> включительно заполнить анкету и предоставить подтверждающие документы в Личном кабинете.</li>
            <li>Успешно пройти входной профессиональный тест.</li>
          </ul>
        </div>
        <div class="section__acceleration-description-text mb-2">
          <p>После верификации полученных заявок и результатов входного профессионального теста, кандидатам будет направлена более подробная информация об этапах обучения.</p>
          <blockquote>
            Статус регионального тренера позволит в течение <b>последующих 3 лет</b> проводить семинары в субъекте Российской Федерации, реализующем программу экспортных семинаров «Жизненный цикл экспортного проекта» Школы экспорта РЭЦ для экспортно ориентированных предприятий данного региона.
          </blockquote>
          <p><b>Форма обучения по программе:</b> очно-заочная с применением электронного обучения и дистанционных образовательных технологий</p>
          <p>Образовательная сессия будет проведена на безвозмездной основе исключительно для кандидатов в тренеры из числа действующих сотрудников региональных центров поддержки экспорта (далее – ЦПЭ). Для остальных кандидатов обучение будет проведено <b class="blue-text">на платной основе</b> в соответствии с тарифами Школы экспорта РЭЦ.</p>
        </div>
        <div class="section__acceleration-description-text mb-2">
          <div class="container-grid section__coaches-page-docs mb-3">
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg" />

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Стоимость обучения</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a target="_blank" download="Стоимость обучения.pdf" href="https://exporteduru.servicecdn.ru/media/default/0002/73/486d12cac8121f6831d14e2c72f7bdf9ddf1e1e4.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
          </div>
          <p style="padding: 0; margin-bottom: 0;">Обучение по программе и аттестация кандидатов в тренеры планируется в период с 30 августа по 30 ноября 2022 года. Даты могут быть скорректированы.</p>
        </div>
        <div class="mb-4">
          <a href="/user/selection-tutor" style="margin: 0;" class="link-target"><span>Принять участие в отборе</span></a>
        </div>
      </div>
    </div>
    <div class="accordion__item container-shadow">
      <div class="accordion__header" id="headingTwo">
        <h2 class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
            aria-controls="collapseOne">
          Возможности для региональных тренеров
        </h2>
      </div>
      <div id="collapseTwo" class="collapse accordion__body" aria-labelledby="headingOne"
           data-parent="#acceleration-learn">
        <div class="section__acceleration-description-text">
          <div class="mb-4">
            <h3 class="mb-2">В случае успешного прохождения обучения и аттестации:</h3>
            <ul class="list list_dotted">
              <li>Вы будете <span class="text-danger">включены в пул аккредитованных тренеров</span> Школы экспорта РЭЦ.</li>
              <li>Вас будут привлекать <span class="text-danger">на платной основе для проведения экспортных семинаров</span>, реализуемых на базе региональных центров поддержки экспорта, в соответствии с рекомендованной Тарификацией Школы экспорта РЭЦ;</li>
              <li>Вы сможете <span class="text-danger">повысить свой социальный и профессиональный статус</span> &ndash; новый уровень взаимодействия с представителями инфраструктуры поддержки экспортно ориентированных субъектов малого и среднего предпринимательства, бизнесом, расширение контактов на региональном уровне;</li>
              <li>Вы получите <span class="text-danger">статус специалиста для дальнейшего развития карьеры</span> &ndash; вклад в личный бренд;</li>
              <li>Для Вас будут предусмотрены <span class="text-danger">специальные условия (скидки) на обучение</span> по другим программам Школы экспорта РЭЦ.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion__item container-shadow">
      <div class="accordion__header" id="headingThree">
        <h2 class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
            aria-controls="collapseOne">
          Требования к кандидатам в тренеры
        </h2>
      </div>
      <div id="collapseThree" class="collapse accordion__body" aria-labelledby="headingOne"
           data-parent="#acceleration-learn">
        <div class="table-wrapper table-wrapper_scrolled mb-7 section__acceleration-description-text">
          <table class="table_bordered">
            <thead>
            <tr>
              <th colspan="2" class="text-center">ТРЕБОВАНИЯ К КАНДИДАТАМ В ТРЕНЕРЫ</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <p>Квалификационные требования</p>
              </td>
              <td>
                <ul class="list list_dotted">
                  <li>законченное высшее образование (специалитет, магистратура);</li>
                  <li>дополнительное образование в области внешнеэкономической деятельности (желательно);</li>
                  <li>дополнительное образование в области обучения взрослых: тренинги для тренеров, программы обучения коучингу, менторингу и т.п. (желательно);</li>
                  <li>гражданство &ndash; Российская Федерация.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Опыт</p>
              </td>
              <td>
                <ul class="list list_dotted">
                  <li>в экспортной деятельности &ndash; от 1 года;</li>
                  <li>в сфере, соответствующей теме курса программы &ndash; от 5 лет;</li>
                  <li>по сопровождению процесса выхода компании на международные рынки (желательно);</li>
                  <li>по обучению взрослых (тренерская, преподавательская деятельность) &ndash; от 3 лет (желательно);</li>
                  <li>по администрированию проектов (информационное и организационное).</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>Профессиональные знания и навыки&nbsp;</p>
              </td>
              <td>
                <ul class="list list_dotted">
                  <li>умение работать с группой людей в интерактивном обучающем формате;</li>
                  <li>умение сформировать и провести комплекс активных обучающих мероприятий, объединённых единой тематикой;</li>
                  <li>умение оценить аудиторию слушателей и откорректировать контент обучения, не нарушая утвержденную структуру и логику программы и требования Школы экспорта РЭЦ;</li>
                  <li>знание основ ВЭД;</li>
                  <li>знание системы поддержки экспорта;</li>
                  <li>знание мер государственной поддержки экспорта, знание продуктов и услуг РЭЦ и ЦПЭ (желательно);</li>
                  <li>продвинутый пользователь офисных программ, образовательных онлайн-платформ, социальных сетей и других современных способов электронных коммуникаций.</li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Region"
}
</script>

<style scoped>

</style>